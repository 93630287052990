@import "../../global/styles/global-colors.scss";

.landing-container {
  padding: 121px 0 40px 0;
  header {
    > h5 {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 28px;
      span {
        color: $orange-peel;
      }
    }
  }
  .main-content {
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 50px 110px;
  }
  @media (max-width: 728px) {
    header {
      > h5 {
        font-size: 28px;
      }
    }
    .main-content {
      padding: 40px 20px;
    }
  }
}
