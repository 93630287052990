@import "../../../global/styles/global-colors.scss";


.card-search {
    position: absolute;
    border-radius: 0.25rem;
    width: 100%;
    background: white;
    z-index: 9;
    .card-search-result {
        display: none;
        width: 100%;
        margin-top: 5px;
        z-index: 10;
        height: auto;
        max-height: 250px;
        overflow-y: auto;
        border: none;
    }


    &.search-on {
        box-shadow: 5px 6px 23px -12px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 5px 6px 23px -12px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 5px 6px 23px -12px rgba(0, 0, 0, 0.75);

        .card-search-result {
            display: block;
        }
    }
    h5 {
        cursor: pointer;
    }

    .list-result{
        li {
            margin-left: 30px;
            cursor: pointer;
            .small {
                padding-left: 4px;
                font-size: 70%;
                color: rgba(0, 0, 0, 0.6);
            }
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .li-result{
        padding-left: 0;
    }
}