@import "../../../global/styles/global-colors.scss";
@import "../../../global/styles/global-form.scss";

.create-account-form {
  .form-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }
  .input-title {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
  .form-wrapper {
    .text-box .is-valid {
      height: 48px;
    }
  }
  @media (max-width: 728px) {
    margin-top: 40px;
  }
}
