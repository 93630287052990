@import "../../../../global/styles/global-colors.scss";

.form-row {
    background: $dp-bg-light;
    animation: mymove 1s infinite;
}

@keyframes mymove {
    from {
        background-color: $dp-bg-light;
    }

    to {
        background-color: $fun-green;
    }
}