@import '../../global/styles/global-colors.scss';

.search-form{
  min-width: 230px;
}

.rbt-aux{
  .rbt-close{
    border-radius: 50%;
    border: none;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 3px;
    padding-top: 2px;
  }
}
.navigation-bar-container {
  background-color: $orange-peel !important;

  img {
    cursor: pointer;
  }

  .nav-link {
    color: white !important;
    opacity: 1 !important;
  }

  .candidates-dropdown, .create-new, .profile-dropdown {
    .dropdown-toggle {
      font-weight: bold;
    }
    .dropdown-menu{
      border: $dp-text-light;
      margin-top: 11px;
      box-shadow: 5px 3px 13px -3px rgba(0,0,0,0.5);
-webkit-box-shadow: 5px 3px 13px -3px rgba(0,0,0,0.5);
-moz-box-shadow: 5px 3px 13px -3px rgba(0,0,0,0.5);
    }
    .dropdown-item, .dropdown-header{
      padding-left: 28px;
      padding-right: 28px;
      padding-top: .55em;
      padding-bottom: .55em;
      i{
        margin-right: 5px;
      }
    }
  }

  .create-new {
    padding-right: 10px;
    .dropdown-toggle {
      font-weight: normal;

      &::after {
        display: none;
      }
    }
  }
}