@import "../../../global/styles/global-colors.scss";
@import "../../../global/styles/global-form.scss";

.page-four-container {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  padding: 50px 40px;
  margin-bottom: 40px;
  .category-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    span {
      color: $persian-red;
    }
    .optional {
      color: unset;
      font-style: italic;
      font-weight: 400;
    }
  }
  .skills-container {
    display: inline-block;
    width: 100%;
  }
  @media (min-width: 1024px) {
    .skills-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
    }
  }
}
