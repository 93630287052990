@import "../../../global/styles/global-colors.scss";

.image-holder{
    text-align: center;
    img{
        margin-top: -90px;
        max-height: 150px;
        border-radius: 30%;
    }
}
.col-img-profile{
    margin-top: 75px !important;
}   