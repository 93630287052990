@import "./global-colors.scss";

textarea {
  background-image: none !important;
  border: 1px solid $prussian-blue !important;
  &:focus {
    border-color: rgba(249, 160, 0, 0.6) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
      0 0 8px rgba(249, 160, 0, 0.6) !important;
    outline: 0 none !important;
  }
}

.danger-label {
  > p {
    color: $persian-red;
  }
}

.form-wrapper {
  text-align: left;
  .text-box-responsive {
    width: 100% !important;
  }
  .input-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    color: $black-pearl;
    > span {
      color: $persian-red;
    }
  }
  .form-check {
    margin-top: 10px;
  }
  .form-check-input {
    width: 20px;
    height: 20px;
    &:checked {
      background-color: $orange-peel;
    }
    &:focus {
      border-color: rgba(249, 160, 0, 0.6);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(249, 160, 0, 0.6);
      outline: 0 none;
    }
  }
  .form-check-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
  .text-box {
    display: inline-block;
    // width: 350px;
    width: 100%;
    position: relative;
    margin: 8px 0;
    z-index: 1;
    > label {
      margin: 0;
      font-size: 15px;
      font-weight: bold;
      line-height: 12px;
      letter-spacing: 1px;
      position: absolute;
      padding: 13px 0px 0px 15px;
      color: #98989899;
      transition: 0.25s all ease;
      top: 0px;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: white;
      font-family: "Poppins", sans-serif;
    }
    .is-valid {
      padding: 10px 10px 10px 15px;
      background-color: white;
      background-image: none !important;
      + label {
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        top: -6px;
        padding-top: 0;
        color: #525252;
        z-index: unset;
        padding-left: 0;
        margin-left: 10px;
        background-color: white;
        width: unset;
        height: unset;
      }
    }
    select {
      border: 1px solid $prussian-blue;
      border-radius: 4px;
      height: 40px;
      padding: 10px 10px 10px 15px;
      box-shadow: none;
      background-color: transparent;
      font-family: "Poppins", sans-serif;
      &:focus {
        padding: 10px 10px 10px 15px;
        border-color: rgba(249, 160, 0, 0.6);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(249, 160, 0, 0.6);
        outline: 0 none;
        + label {
          font-size: 12px;
          position: absolute;
          color: #525252;
          z-index: unset;
          background-color: white;
        }
      }
    }
    > input {
      border: 1px solid $prussian-blue;
      border-radius: 4px;
      height: 40px;
      padding: 10px 10px 10px 15px;
      box-shadow: none;
      background-color: transparent;
      font-family: "Poppins", sans-serif;
      &:read-only {
        background-color: #dddddda6 !important;
        cursor: not-allowed;
      }
      &:-webkit-autofill {
        z-index: unset;
      }
      &:focus {
        padding: 10px 10px 10px 15px;
        border-color: rgba(249, 160, 0, 0.6);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(249, 160, 0, 0.6);
        outline: 0 none;
        + label {
          font-size: 12px;
          position: absolute;
          color: #525252;
          z-index: unset;
          background-color: white;
        }
      }

      &.has-error {
        background-color: #f557531a;
      }
    }
  }
}

.flex-two-section {
  display: flex;
  align-items: center;
  justify-content: center;
  .flex-container {
    flex: 2;
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      margin-left: 15px;
    }
  }
  @media (max-width: 728px) {
    display: inline;
  }
}

.buttons-container {
  width: 100%;
  text-align: right;
  margin-top: 30px;
  .save-button {
    background-color: $ghost-white;
    width: 100%;
    height: 48px;
    color: $evening-symphony;
    border: $prussian-blue 1px solid !important;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
    &:active {
      background: $ghost-white !important;
      border: unset !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .submit-button {
    background-color: $orange-peel;
    width: 100%;
    height: 48px;
    border: none;
    &:active {
      background: $orange-peel !important;
      border: unset !important;
      outline: none !important;
      box-shadow: none !important;
    }
    &:hover {
      background-color: $orange-peel;
    }
  }
  .is-disabled {
    opacity: 0.5;
    &:active {
      background: $orange-peel !important;
      border: unset !important;
      outline: none !important;
      box-shadow: none !important;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
}

.resume-forms-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    max-width: 211.49px;
    &:last-child {
      margin-left: 20px;
    }
  }
}
