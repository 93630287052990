@import "../../global/styles/global-colors.scss";

.source-container {
  margin-top: 60px;
  height: 276px;
  background-image: url('../../assets/images/pooling_assets/dash-bg.png');
  background-size: contain;
  text-align: center;
  
  .source-text{
    margin-top: 73px;
    color: $white;
    .btn{
      width: 344px;
      color: $white;
      margin-top: 36px;
    }
  }
}

.dropzone-container{
  border: 1px dashed $link-water;
  border-radius: .25rem;
}