/* make the customizations */
@import "./global/styles/global-colors.scss";
@import "./global/styles/global-reset.scss";


@import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/bootstrap-utilities";

@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(( // use theme parameters where possible
      alpine-active-color: $orange-peel));

  .actions-button-cell {
    overflow: visible;
  }

  .ag-row {
    z-index: 0;
  }

  .ag-row.ag-row-focus {
    z-index: 1;
  }
}

body {
  background-color: $whisper;
}

.table-dp-small {
  font-size: 0.9em;

  thead {
    vertical-align: middle;
  }
}

.border-none {
  border: none !important;
}

.main-container {
  margin-top: 60px;

  .header-container {
    padding-top: 64px;
  }

  .main-card {
    margin-top: 36px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.borderless {
  border: none;

  &::after {
    display: none;
  }
}

.easy-edit-hover-on.easy-edit-wrapper {
  font-style: normal !important;
  opacity: 0.85;
}

.easy-edit-component-wrapper {
  input {
    border: none;
    outline-width: 0;
    padding: 0;
  }
}

.easy-edit-inline-wrapper {
  .easy-edit-validation-error {
    font-size: 12px;
    font-weight: normal;
    color: $persian-red !important;
  }
}

.easy-edit-button-wrapper {
  button {
    background-color: transparent;

    &:first-child {
      color: $dark-orchid;
    }

    &:last-child {
      color: $persian-red;
    }
  }
}

@include media-breakpoint-up(xl) {

  .modal-half {
    .modal-fullscreen {
      right: 0;
      position: fixed;
      width: 35vw;
    }
  }
}

.text-light-green {
  color: $light-green;
}

.font-12 {
  font-size: 12px;
}

.text-strong {
  font-weight: bold;
}

.input-line {
  border-radius: 0;
  border-top: none;
  border-left: none;
  padding: 5px 0;
  border-right: none;
}

.input-line-typeahead {
  input {
    border-radius: 0;
    border-top: none;
    border-left: none;
    padding: 5px 0;
    border-right: none;
  }
}

.section-scrollable {
  overflow-x: auto;

  p {
    width: max-content
  }
}

.dot-priority-3 {
  color: $persian-red;
}

.dot-priority-2 {
  color: $orange-peel;
}

.dot-priority-1 {
  color: $jungle-green;
}

.dot-priority-0 {
  color: $dp-text-light;
}

.dot-priority-bg-3 {
  background: $persian-red !important;
}

.dot-priority-bg-2 {
  background: $orange-peel !important;
}

.dot-priority-bg-1 {
  background: $jungle-green !important;
}

.dot-priority-bg-0 {
  background: $dp-text-light !important;
}

.btn-white-circle {
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 50%;
  color: $jungle-green;
}

.btn-text-white {
  @include text-white;
}

.btn-fat {
  @include text-white;
  padding: 13px 26px;
}

.btn-fat {
  @include text-white;
  padding: 13px 26px;
}

.btn-wide {
  padding-right: 50px;
  padding-left: 50px;
}

.btn-outline-secondary {
  color: $lynch;
  border-color: $lynch;
}

.badge-wide {
  padding-left: 20px;
  padding-right: 20px;
}

.text-muted-af {
  color: $dp-text-light-af;
}

.admin-listgroup {
  &.list-group {

    a,
    button {
      border-left: 3px solid transparent;
      border-color: transparent;
      padding: 16px 23px;

      &.active {
        border-color: transparent;
        border-left: 3px solid $orange-peel;
        background: $white;
        font-weight: bold;
        color: $orange-peel;
      }
    }
  }
}

@media (min-width: 1024px) {
  .table-wrap {
    table-layout: fixed;
    width: 100%;

    tr {
      td:not(:last-child) {

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.width-50 {
  width: 50px;
}

.width-100 {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
}

.main-card-with-search {
  margin-top: 70px !important;
}

.text-twitter {
  color: #1BB1E4;
}

.text-youtube {
  color: #F70000;
}

.text-linkedin {
  color: #0A66C2;
}

.text-facebook {
  color: #1877F2;
}

.dark {

  // $enable-gradients: true;

  /* redefine theme colors for dark theme */
  // $primary: #012345;
  // $secondary: #111111;
  // $success: #222222;
  $dark: #000;

  background-color: $dark;

  $theme-colors: ("primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "danger": $danger,
      "info": $indigo,
      "dark": $dark,
      "light": #aaa,
    );


  /* redefine theme color variables */
  @each $color,
  $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

  @each $color,
  $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $body-color: #eeeeee;
  $body-bg: #263C5C;


  .settings-container {
    background-color: $dark;
  }

  .card,
  .resume-details-container .resume-panel-container,
  .modal-content,
  .react-kanban-board .react-kanban-column {
    background-color: $body-bg !important;
  }

  .kanban-container {
    background-color: $dark !important;
  }

  .nav-pills .nav-link.active {
    background-color: transparent !important;
  }

  .col-img-profile {
    margin-top: 75px !important;
  }

  
.custom-badge{
  display: inline-block !important;;
  padding: 0.35em 1em !important;;
  font-size: 0.75em !important;;
  font-weight: 700 !important;;
  line-height: 1 !important;;
  color: #ffffff !important;;
  text-align: center !important;;
  white-space: nowrap !important;;
  vertical-align: baseline !important;;
  border-radius: 10px !important;;
  background-color: $orange-peel;
}


  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  @import "bootstrap";
}