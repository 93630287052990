@import "../../../global/styles/global-colors.scss";

.modal-footer{
  border-top: none;
}

.rbt-token .rbt-token-remove-button {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}

.rbt-input-multi{
  &.focus{
    border-color: #fcd080 !important;
    box-shadow: 0 0 0 0.25rem rgb(249 160 0 / 25%) !important;
  }
  .rbt-token{
    background:rgb(249 160 0 / 85%);
    color: $white;
  }
}