.req-holder{
    padding-left: 10px;
    // display: flex;
    // align-items: center;
    >i {
        margin-right: 7px;
    }

    >span{
        // line-height: 16px;
        // margin: 5px 0;
        font-weight: bold;
    }
}