@import "../../global/styles/global-colors.scss";
@import "../../global/styles/global-form.scss";

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .content-container {
    width: 539px;
  }
  .login-panel {
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 50px 110px;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    .form-title {
      margin-bottom: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 540px) {
    .login-panel {
      padding: 50px 20px;
      margin: 15px;
      img{
        max-width:    320px;
        margin: 0 auto;
      }
    }
  }

}
