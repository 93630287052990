@import "../../../global/styles/global-colors.scss";

.admin-table{
    .table > thead, .table > tbody {
        border-top:none;
    }
    .table > tbody td{
        padding-left: 15px;
    }
    .table > thead th{
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) > * {
        background-color: rgb(241, 241, 241, .05)
    }
}