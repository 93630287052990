@import "../../global/styles/global-colors.scss";

.small-dot {
    font-size: 6px;
    vertical-align: middle;
}

.sick-days-breach {
    background-color: #FFF2F2 !important;
}

.requirement-cell {
    padding-left: 0 !important;
    border-left: 9px solid transparent !important;
}


.high-prio-border {
    border-left-color: $persian-red !important;
}

.medium-prio-border {
    border-left-color: $orange-peel !important;
}

.low-prio-border {
    border-left-color: $jungle-green !important;
}

.not-prio-border {
    border-left-color: $dp-text-light !important;
}