$orange-peel: #F9A000;
$white: #ffffff;
$link-water: #ced4da;
$ghost-white: #F7F8FC;
$jungle-green: #2DBB54;
$dark-orchid: #2CBE51;
$light-green: #00D108;
$razzle-dazzle-rose: #721C5C;
$white-smoke: #F4F4F4;
$kanban-bg: #F3F3F3;
$evening-symphony: #4D627B;
$boeing-blue: #3D4651;
$dp-text-light: #5F5F5F;
$dp-text-light-af: #ABB0B7;
$dp-bg-light: #EAEAEA;
$black-pearl: #151617;
$prussian-blue: #C2CEDB;
$whisper: #E5E5E5;
$gainsboro: #DEDEDE;
$link-blue: #0066CC;
$blue: #3996D9;
$persian-red: #D93123;
$sky-blue: #3996D9;
$super-nova: #FFAC30;
$orange-text: #A36800;
$lynch: #66788A;
$fun-green: #29BF59;
$link-green: #008B06;



@mixin text-white {
    color: $white;
    &:hover, &:active, &:focus{
        color: $white;
    }
}