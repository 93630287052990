@import "../../../global/styles/global-colors.scss";
@import "../../../global/styles/global-form.scss";

.page-three-container {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  padding: 50px 40px;
  margin-bottom: 40px;
  .category-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    span {
        color: $persian-red;
    }
    .optional {
        color: unset;
        font-style: italic;
        font-weight: 400;
    }
}
  .flex-container {
    display: inline-block;
    width: 100%;
  }
  .accordion-item {
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    margin-bottom: 20px;
    border: none;
    .accordion-button {
      background: #ffffff;
      border-bottom: none;
      &:last-of-type {
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
      &:focus {
        border-color: rgba(249, 160, 0, 0.6);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
          0 0 8px rgba(249, 160, 0, 0.6);
        outline: 0 none;
      }
    }
    .accordion-collapse {
      background: #ffffff;
      box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
        0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 4px;
    }
  }
  @media (min-width: 1024px) {
    .flex-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
