@import '../../global/styles/global-colors.scss';

.landing-footer-container {
    width: 100%;
    padding: 0 180px;
    margin-top: 40px;
    > p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: $evening-symphony;
    }
    @media (max-width: 728px) {
        padding: 0 30px;
    }
}