@import "../../global/styles/global-colors.scss";
@import "../../global/styles/global-form.scss";

.masonry{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: stretch;
	width: 100%;
	margin: auto;
}
.column{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: stretch;
	flex-grow: 1;
}

.toast-container {
  position: fixed !important;
  z-index: 1000;
}

.file-cv {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resume-details-container {
  h5 {
    font-family: 'Inria Serif', serif;
    font-weight: bold;
  }

  padding-top: 65px;

  .header {
    color: $black-pearl;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 40px;

    span {
      font-size: 14px;
      color: $fun-green;
      font-weight: 400;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .top-46 {
    margin-top: 46px;

    .section-title {
      margin-left: 15px;
    }
  }

  .group-detail-hover-holder {
    position: relative;
    padding: 15px;

    .details-title {
      margin-bottom: 0;
      font-weight: bold;

      span {
        font-weight: normal;
        color: $dp-text-light;
      }
    }

    .details-subtitle {
      margin-bottom: 0;
      color: $orange-peel;

      &.details-stack {
        margin-top: 5px;

        span {
          margin-right: 3px;
          margin-bottom: 3px;
        }
      }
    }

    .details-description {
      white-space: pre-wrap;
      margin-top: 10px;
      margin-bottom: 0;
      color: $dp-text-light;
    }

    .detail-actions {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba($color: $dp-bg-light, $alpha: 0.5);
      height: 100%;
      width: 100%;

      .text-center {
        position: relative;
        top: 20%;
        width: 100%;
      }
    }

    &:hover {
      background: $dp-bg-light;

      .detail-actions {
        display: block;
      }
    }

  }

  .resume-panel-container {
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 50px 40px;
    margin-bottom: 40px;

    .user-name-header {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 39px;
      color: $black-pearl;
      display: flex;
      align-items: center;
      margin-bottom: 17px;

      span {
        font-size: 14px;
        color: $fun-green;
        font-weight: 400;
        margin-left: auto;

        &:hover {
          cursor: pointer;
        }
      }

    }

    .user-position {
      font-family: 'Inria Serif', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      color: $orange-peel;
    }

    .user-details-container {
      margin-top: 0;

      p {
        color: $dp-text-light;
      }
    }

    .resume-details {
      padding: 30px 0;

      .details-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: $fun-green;
          font-weight: 400;
          margin-left: auto;
          color: $fun-green;

          i {
            &:first-of-type {
              padding-right: 15px;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .details-sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $black-pearl;
        margin-bottom: 10px;
      }

      .details-margin {
        margin-bottom: 15px !important;
      }

      .details-position {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $orange-peel;
        margin: 0;
      }

      .details-position-date {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $orange-peel;
        margin: 0;
        display: flex;
        align-self: center;
        width: 100%;

        span {
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          color: $black-pearl;
          margin-left: auto;
        }
      }

      .details-content {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: $evening-symphony;
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .user-details {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $evening-symphony;
      margin-bottom: 0;
    }

    .skills-grid {
      display: inline;
    }

    .skills-title {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $black-pearl;
      margin-bottom: 0;

      span {
        color: $orange-peel;
      }
    }

    .skills-experience {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: $evening-symphony;
    }

    @media (min-width: 1024px) {
      .skills-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
      }
    }
  }
}