@import "../../../global/styles/global-colors.scss";


.search-btn{
    input{
        border-right: none;
    }
    .btn-outline-secondary{
        border-left: none !important;
        border: 1px solid #ced4da;
    }
}

.custom-badge{
    display: inline-block;
    padding: 0.35em 1em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    background-color: $orange-peel;
}

.tags-card{
    display: flex;
    flex-wrap: wrap;

    .dropdown{
        margin: 5px;
        .dropdown-toggle{
            border: none;
            &::after{
                content:none;
            }
        }
    }
}