@import "../../global/styles/global-colors.scss";

.landing-container {
  padding: 121px 0 40px 0;
  header {
    > h5 {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 28px;
      span {
        color: $orange-peel;
      }
    }
  }

}
