@import "../../../global/styles/global-colors.scss";

.settings-tab{
    .nav-item:first-child{
        .nav-link{
            margin-left: 0.25rem !important;
        }
    }
    .nav-link{
        color: #000000;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-left: 0;
        font-weight: 500;
        padding-bottom: 3px;
        padding-right: 0;
        &.active{
            border-bottom: 3px solid $orange-peel;
            border-radius: 0;
            background: none;
            color: #000000;
        }
    }
}