@import '../../../global/styles/global-colors.scss';

.join-description-container {
    .join-description-header {
        font-weight: 500;
        font-size: 36px;
        line-height: 46px;
    }

    .carousel-item-holder{
        min-height: 430px;
    }
    .carousel-indicators [data-bs-target] {
        background-color: $lynch;
    }
    .mb-three {
        margin-bottom: 34px;
    }
    .application-title {
        margin-top: 20px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        width: 70%;
        > span {
            color: $orange-peel
        }
    }
    .application-message {
        width: 70%;
    }
    .message-container {
        margin-top: 40px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $evening-symphony;
        .message-highlight {
            padding-top: 20px;
            font-weight: 700;
        }
    }
    @media (max-width: 728px) {
        .join-description-header {
            font-size: 28px;
            line-height: 40px;
            text-align: center;
        }
        .application-title {
            text-align: center;
            width: 100%;
        }
        .message-container {
            text-align: center;
            .application-message {
                width: 100%;
            }
        }
    }
}