@import "../../global/styles/global-colors.scss";

.verification-message-container {
  .verification-message-panel {
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 50px 0;
    width: 539px;
    text-align: center;
    .panel-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 30px;
    }
    > img {
      width: 65px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $evening-symphony;
      margin-top: 40px;
      padding: 0 60px;
    }
  }
  @media (max-width: 728px) {
    .verification-message-container {
      .verification-message-panel {
        width: 100%;
      }
    }
  }
}
