@import "../../../global/styles/global-colors.scss";
@import "../../../global/styles/global-form.scss";

.first-page-form {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 4px;
  padding: 50px 40px;
}
