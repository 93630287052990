@import "../../global/styles/global-colors.scss";

.verification-container {
  padding-top: 125px;
  .verification-title {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    text-align: center;
    span {
      color: $orange-peel;
    }
  }
  .verification-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
}
