@import "../../global/styles/global-colors.scss";

.kanban-container{
    min-height: 100vh;
    height: 100%;
    background: $ghost-white;
}

.bg-light{
    background: $ghost-white;
}

.react-kanban-board{
    // height: 85vh;
    .react-kanban-column{
        background: $kanban-bg !important;
        border: 2px solid transparent;
        min-height: 600px !important;
    }
    .kanban-edit-action {
        display: none;
    }
    .react-kanban-card__title{
        border-bottom: none !important;
        padding-bottom: 0px !important;
        font-weight: normal !important;
        color: $blue;
    }
    
    .react-kanban-card__description{
        padding-top: 0 !important;
    }

}

.edit-kanban {
    .react-kanban-board{
        .react-kanban-column{
            border: 2px dashed $orange-peel !important;
        }
    }
    .kanban-edit-action {
        display: inline-block;
    }
}

@media (min-width: 768px) {
    .kanban-header-row{
        margin-top: -30px;
    }
}